import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AddressAutoComponent } from 'components/AddressAutocomplete/AddressComponent'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetDebtorAccountDocument,
  useCreateDebtorAccountMutation,
  useGetDebtorAccountQuery,
  useGetDebtorTypeQuery,
  useGetHomeSummaryQuery,
  useGetTaxSchemeQuery,
  useUpdateDebtorAccountMutation,
  useUpdateDebtorAccountWithItemMutation,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useAddressAutoComplete } from 'helpers/Hooks/useAddressAutoComplete'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface DebtorAccountProps {
  companyRegNo: string
  debtorName: string
  debtorType: string
  mobileNo: number
  contactNo: number
  email: string
  creditTerms: string
  tax: string
  GSTRegNo: string
  GSTStartDate: string
  remark: string
  Contact: any
  isVip: boolean
  isCompany: boolean
}

export const DebtorAccountForm = (props: any) => {
  const { mode }: any = props
  const { CompanyID, DebtorID }: any = useParams()
  const { creditorType, DebtorAccountID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [country, setCountry] = useState('')
  const [, setState] = useState('')
  const [, setCity] = useState('')
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openDirectorDialog, setOpenDirectorDialog] = useState<boolean>(false)
  const [contactData, setContactData] = useState([])
  const [directorData, setDirectorData] = useState([])
  const [checkContactError, setCheckContactError] = useState([])
  const [checkDirectorError, setCheckDirectorError] = useState([])
  const [submitMode, setSubmitMode] = useState<string>()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  let location = useLocation()
  const editData = location?.state as any

  const DebtorProfileSchema = yup.object().shape({
    debtorType: CommonYupValidation.requireField(SystemMsgs.debtorType()),
    companyRegNo: CommonYupValidation.requireField(SystemMsgs.companyRegNo()),
    debtorName: CommonYupValidation.requireField(SystemMsgs.name()),
    //phoneNo: CommonYupValidation.requireField(SystemMsgs.phoneNo()),
    //email: CommonYupValidation?.emailNotRequired(),
    creditTerms: CommonYupValidation.requireField(SystemMsgs.creditTerms()),
    //tax: CommonYupValidation.requireField(SystemMsgs.tax()),
    //region: CommonYupValidation.requireField(SystemMsgs.region()),
    //GSTRegNo: CommonYupValidation.requireField(SystemMsgs.GSTRegNo()),
    // remark: CommonYupValidation.requireField(SystemMsgs.remark()),
    // bankAccNo: CommonYupValidation.requireField(SystemMsgs.bankAccNo()),
    // bankName: CommonYupValidation.requireField(SystemMsgs.bankName()),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitted },
  } = useForm<DebtorAccountProps>({
    mode: 'onSubmit',
    resolver: yupResolver(DebtorProfileSchema),
  })

  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const {
    loading: DebtorTypeAccountLoading,
    error: DebtorTypeAccountError,
    data: { getDebtorType } = { getDebtorType: [] },
  } = useGetDebtorTypeQuery({
    fetchPolicy: 'network-only',
    // variables: {
    //   CompanyID: CompanyID,
    // },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  useEffect(() => {
    if (mode === 'edit' && editData) {
      // setValue('creditorType', editData.CreditorTypeID)
      // setValue('tax', editData.TaxSchemeID)
      // setValue('bankName', e?.target?.value)
      const Contact = editData.DebtorContact?.map((el, index) => {
        return {
          name: el?.Name,
          contactNo: el?.ContactNo,
          email: el?.Email,
          designation: el?.Designation,
        }
      })

      contactData.push(...Contact)

      let array_of_address = [editData?.Address?.address] || []
      setQuery(array_of_address.join(', '))
      initializeEditValue({
        address: array_of_address.join(', '),
        //address: editData?.Address?.address,
        country: editData?.Address?.country,
        state: editData?.Address?.state,
        city: editData?.Address?.city,
        postCode: editData?.Address?.postCode,
      })
    }
  }, [mode, editData])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'HierarchyItems',
  })

  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    query,
    autoCompleteRef,
    state,
    setQuery,
    initializeEditValue,
  } = useAddressAutoComplete()

  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: {
      AccTaxClass: 'OUTPUT',
      orderByAsc: 'Code',
    },
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    createDebtorAccount,
    { loading: createLoading, error: createError },
  ] = useCreateDebtorAccountMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/debtor-account`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    updateDebtorAccount,
    { loading: updateLoading, error: updateError },
  ] = useUpdateDebtorAccountMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorID}/detail`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  const [
    updateDebtorAccountWithItemMutation,
    { loading: updateDebtorLoading, error: updateDebtorError },
  ] = useUpdateDebtorAccountWithItemMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorID}/detail`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })
  const onCreateContact = data => {
    if (checkContact(watch('Contact'))?.length === 0) {
      contactData.push({
        name: data.contactName,
        contactNo: data.contactNo,
        email: data.emailAddress,
        designation: data.designation,
      })
    }
  }

  const onUpdateContact = (data, index) => {
    if (checkContact(watch('Contact'))?.length === 0) {
      contactData[index].name = data.contactName
      contactData[index].contactNo = data.contactNo
      contactData[index].email = data.emailAddress
      contactData[index].designation = data.designation
    }
  }
  const onDeleteContact = index => {
    contactData.splice(index, 1)
  }

  const onSubmit = (data, state, contact) => {
    if (
      state.address &&
      state.state.name &&
      state.city.name &&
      state.country.name &&
      state.postcode.postcode
    ) {
      if (mode === 'add') {
        createDebtorAccount({
          variables: {
            input: {
              CompanyID: CompanyID,
              DebtorTypeID: data?.debtorType,
              CompanyRegNo: data?.companyRegNo,
              DebtorName: data?.debtorName,
              MobileNo: data?.mobileNo,
              OfficeNo: data?.officeNo,
              Email: data?.email,
              IsVIP: data?.isVip,
              IsCompany: data?.isCompany,
              Address: {
                address: state.address,
                state: state.state.name,
                city: state.city.name,
                country: state.country.name,
                postCode: state.postcode.postcode,
              },
              CreditTerm: parseFloat(amtNumStr(data?.creditTerms)),
              TaxSchemeID: data?.tax,
              GSTRegNo: data?.GSTRegNo,
              GSTStartDate:
                data?.GSTStartDate !== ''
                  ? new Date(data?.GSTStartDate)?.toISOString()
                  : null,
              Remark: data?.remark,
              // CompanyID: data?.creditorType,
            },
            contactItem: contact?.map(y => {
              return {
                Name: y?.name,
                ContactNo: y?.contactNo,
                Designation: y?.designation,
                Email: y?.email,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetDebtorAccountDocument,
            },
          ],
        })
      } else if (mode === 'edit') {
        updateDebtorAccountWithItemMutation({
          variables: {
            input: {
              DebtorAccountID: DebtorID,
              DebtorTypeID: data?.debtorType,
              CompanyID: CompanyID,
              CompanyRegNo: data?.companyRegNo,
              DebtorName: data?.debtorName,
              MobileNo: data?.mobileNo,
              OfficeNo: data?.officeNo,
              Email: data?.email,
              IsVIP: data?.isVip,
              IsCompany: data?.isCompany,
              Address: {
                address: state.address,
                state: state.state.name,
                city: state.city.name,
                country: state.country.name,
                postCode: state.postcode.postcode,
              },
              CreditTerm: parseFloat(amtNumStr(data?.creditTerms)),
              TaxSchemeID: data?.tax,
              GSTRegNo: data?.GSTRegNo,
              GSTStartDate: new Date(data?.GSTStartDate)?.toISOString() ?? '',
              Remark: data?.remark,
              // CompanyID: data?.creditorType,
            },
            contactItem: contact?.map(y => {
              return {
                Name: y?.name,
                ContactNo: y?.contactNo,
                Designation: y?.designation,
                Email: y?.email,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetDebtorAccountDocument,
              variables: { DebtorAccountID: DebtorID },
            },
          ],
        })
      }
    }
  }

  const checkContact = data => {
    let errorData = []

    if (data.contactName === undefined || data.contactName === '') {
      errorData.push({
        error: 'contactName',
      })
    }
    if (data.contactNo === undefined || data.contactNo === '') {
      errorData.push({
        error: 'contactNo',
      })
    }
    if (data.emailAddress === undefined || data.emailAddress === '') {
      errorData.push({
        error: 'emailAddress',
      })
    }
    if (data.designation === undefined || data.designation === '') {
      errorData.push({
        error: 'designation',
      })
    }
    if (validateEmail(data.emailAddress) === false) {
      errorData.push({
        error: 'invalidEmailAddress',
      })
    }
    setCheckContactError(errorData)
    return errorData
  }
  const checkDirector = data => {
    let errorData = []

    if (data.identityNo === undefined || data.identityNo === '') {
      errorData.push({
        error: 'identityNo',
      })
    }
    if (data.identityType === undefined || data.identityType === '') {
      errorData.push({
        error: 'identityType',
      })
    }
    if (data.contactName === undefined || data.contactName === '') {
      errorData.push({
        error: 'contactName',
      })
    }
    if (data.contactNo === undefined || data.contactNo === '') {
      errorData.push({
        error: 'contactNo',
      })
    }
    if (data.emailAddress === undefined || data.emailAddress === '') {
      errorData.push({
        error: 'emailAddress',
      })
    }
    if (validateEmail(data.emailAddress) === false) {
      errorData.push({
        error: 'invalidEmailAddress',
      })
    }
    setCheckDirectorError(errorData)
    return errorData
  }

  const validateEmail = email => {
    if (
      email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true
    } else {
      return false
    }
  }

  const checkbox = (checkedCondition: boolean, name: string, label: string) => {
    return (
      <Controller
        as={
          <FormControlLabel
            style={{ fontWeight: 'bold' }}
            control={
              <Checkbox
                defaultChecked={checkedCondition}
                name={name}
                color="primary"
                ref={register}
                onChange={e => {
                  setValue(name, e.target.checked)
                }}
              />
            }
            label={label}
          />
        }
        name={name}
        ref={register}
        control={control}
      />
    )
  }
  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { DebtorAccountID: DebtorID },
  })

  return (
    <>
      {DebtorTypeAccountLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {updateDebtorLoading && <Loading />}
      {createLoading && <Loading />}
      {updateLoading && <Loading />}
      {TaxLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-receivable/${CompanyID}`)} //<- back button action
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        //currency={`MYR`}
        routeSegments={[
          { name: 'Accounts Receivable' },
          { name: '..' },
          { name: 'Debtor Profile', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <ContentWrapper footer>
        <CardContents
          section={{
            header: {
              title: 'Debtor Info',
            },
          }}
        >
          <Controller
            render={({ onChange, value }) => (
              <TextField
                select
                name="debtorType"
                label="Debtor Type"
                onChange={e => {
                  setValue('debtorType', e?.target?.value)
                  clearErrors('debtorType')
                }}
                // helperText={errors?.debtorType?.message}
                // error={errors?.debtorType ? true : false}
                defaultValue={editData?.DebtorTypeID}
                required
                helperText={errors?.debtorType?.message}
                error={errors?.debtorType ? true : false}
              >
                {getDebtorType?.map((el, index) => (
                  <MenuItem key={index} value={el.DebtorTypeID}>
                    {`${el.Name}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            onChange={e => {
              setValue('debtorType', e?.target?.value)
            }}
            name="debtorType"
            label="Debtor Type"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            ref={register}
            select
            defaultValue={editData?.DebtorTypeID}
            required
            helperText={errors?.debtorType?.message}
            error={errors?.debtorType ? true : false}
          />
          <Controller
            //  style={{ textOverflow: 'ellipsis' }}
            as={TextField}
            id="standard-basic"
            name="companyRegNo"
            label="Company Registration No."
            required
            //autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.companyRegNo?.message}
            error={errors?.companyRegNo ? true : false}
            ref={register}
            // className="left"
            // InputLabelProps={{ style: {} }}
            defaultValue={editData?.CompanyRegNo}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="debtorName"
            label="Name"
            required
            autoComplete="off"
            control={control}
            // multiline={true}
            fullWidth
            margin="dense"
            helperText={errors?.debtorName?.message}
            error={errors?.debtorName ? true : false}
            ref={register}
            defaultValue={editData?.DebtorName}
          />

          <Controller
            as={
              <PhoneNumInput
                country={user?.countryCode ?? 'my'}
                specialLabel="Phone No.(Office)"
                fullWidth
              />
            }
            name="officeNo"
            type="tel"
            marginTop
            marginBottom
            // helperText={errors?.phoneNo?.message}
            // error={errors?.phoneNo ? true : false}
            margin="normal"
            control={control}
            ref={register}
            defaultValue={editData?.OfficeNo}
          />
          <Controller
            as={
              <PhoneNumInput
                country={user?.countryCode ?? 'my'}
                specialLabel="Mobile No."
                fullWidth
              />
            }
            name="mobileNo"
            type="tel"
            marginTop
            marginBottom
            // helperText={errors?.phoneNo?.message}
            // error={errors?.phoneNo ? true : false}
            margin="normal"
            control={control}
            ref={register}
            defaultValue={editData?.MobileNo}
          />
          <Controller
            as={TextField}
            // <TextField
            id="standard-basic"
            name="email"
            label="Email Address"
            //required
            autoComplete="off"
            control={control}
            // multiline={true}
            fullWidth
            margin="dense"
            // helperText={errors?.email?.message}
            // error={errors?.email ? true : false}
            ref={register}
            defaultValue={editData?.Email}
          />
          <Controller
            as={
              <NumberFormat
                allowNegative={true}
                customInput={TextField}
                autoComplete="off"
                isNumericString
                label="Credit Terms"
                margin="dense"
              />
            }
            id="standard-basic"
            name="creditTerms"
            label="Credit Terms"
            required
            autoComplete="off"
            control={control}
            // multiline={true}
            fullWidth
            margin="dense"
            helperText={errors?.creditTerms?.message}
            error={errors?.creditTerms ? true : false}
            ref={register}
            defaultValue={editData?.CreditTerm}
            className="left"
          />
          <Controller
            render={({ onChange, value }) => (
              <TextField
                select
                name="tax"
                label="Tax"
                className="right"
                style={{ marginTop: '5px' }}
                onChange={e => {
                  setValue('tax', e?.target?.value)
                }}
                // helperText={errors?.tax?.message}
                // error={errors?.tax ? true : false}
                defaultValue={editData?.TaxSchemeID}
              >
                {getTaxScheme?.map((el, index) => (
                  <MenuItem key={index} value={el.TaxSchemeID}>
                    {`${el.Code} ${el?.LatestTax?.Rate}%`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="tax"
            label="Tax"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            ref={register}
            select
            onChange={e => {
              setValue('tax', e?.target?.value)
            }}
            className="right"
            defaultValue={editData?.TaxSchemeID}
          />

          <ListItem style={{ paddingLeft: '2px' }}>
            <Grid container>
              <Grid item xs={7}>
                {checkbox(
                  getDebtorAccount[0]?.IsVIP! || editData?.IsVIP || false,
                  'isVip',
                  'VIP'
                )}
              </Grid>
              <Grid item xs={5}>
                {checkbox(
                  getDebtorAccount[0]?.IsCompany! ||
                    editData?.IsCompany ||
                    false,
                  'isCompany',
                  'Company'
                )}
              </Grid>
            </Grid>

            {/* <FormControl component="fieldset">
              <FormControlLabel
                // value="company"
                control={
                  <Checkbox
                    onChange={e => {
                      handleCheckBoxChange(e)
                    }}
                    color="default"
                    style={{
                      marginLeft: '10px',
                      marginRight: '-5px',
                    }}
                    value="company"
                  />
                }
                label="Company"
                // style={{ margin: '0px 10px' }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      handleCheckBoxChange(e)
                    }}
                    color="default"
                    style={{
                      marginLeft: '10px',
                      marginRight: '-5px',
                    }}
                    value="company"
                  />
                }
                label="VIP"
                style={{ margin: '0px 40px' }}
              />
            </FormControl> */}
          </ListItem>

          <Controller
            as={TextField}
            // <TextField
            id="standard-basic"
            name="GSTRegNo"
            label="GST Registration No."
            //required
            autoComplete="off"
            control={control}
            // multiline={true}
            fullWidth
            margin="normal"
            // helperText={errors?.GSTRegNo?.message}
            // error={errors?.GSTRegNo ? true : false}
            ref={register}
            defaultValue={editData?.GSTRegNo}
            className="left"
            // style={{ padding: '12px' }}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              // required
              as={KeyboardDatePicker}
              name="GSTStartDate"
              label="GST Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              //disablePast={true}
              format="dd/MM/yyyy"
              // value={new Date()}
              value={new Date() || ''}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={mode === 'edit' ? editData?.GSTStartDate : null}
              showTodayButton
              className="right"
              disabled={mode == 'approve-reject'}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            // <TextField
            id="standard-basic"
            name="remark"
            label="Remark"
            //required
            autoComplete="off"
            control={control}
            // multiline={true}
            fullWidth
            margin="dense"
            // helperText={errors?.remark?.message}
            // error={errors?.remark ? true : false}
            ref={register}
            defaultValue={editData?.Remark}
          />
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Address Info',
            },
          }}
        >
          <AddressAutoComponent
            getCountry={getCountry}
            getStatebyCountry={getStatebyCountry}
            getCitybyState={getCitybyState}
            getPostcodebyCity={getPostcodebyCity}
            handleCountryOnChange={handleCountryOnChange}
            handleStateOnChange={handleStateOnChange}
            handleCityOnChange={handleCityOnChange}
            handlePostCodeOnChange={handlePostCodeOnChange}
            handleGoogleOnChange={handleGoogleOnChange}
            autoCompleteRef={autoCompleteRef}
            state={state}
            country={country}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            formType={mode}
            isSubmitted={isSubmitted}
          />
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Contact details',
              onClickAction: () => {
                resetMenu()
                setOpenDialog(true)
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="small"
                  style={{
                    width: '20px',
                    height: '20px',
                    margin: '0',
                    background: theme.palette.primary.main,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                    marginTop: '10px',
                    marginRight: '10px',
                  }}
                />
              ),
            },
          }}
        >
          <List
            style={{ width: '100%', paddingBottom: '0px' }}
            //className={addStyle.list}
          >
            {contactData.map((el, index) => {
              // if (
              //   handlePermHidden({
              //     contractID: contractID,
              //     permEnum: el.permission,
              //   })
              // )
              return (
                <ListItem
                  key={index}
                  onClick={() =>
                    history.push({
                      pathname: el.path,
                    })
                  }
                  style={{ marginLeft: '-5px', marginTop: '-15px' }}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="desc">
                          <span className="xsTitle">
                            {el.name} ({el.designation})
                          </span>
                        </span>
                      </>
                    }
                    secondary={
                      <span className="desc">
                        <span className="desc">
                          <span
                          //onClick={}
                          >
                            <PhoneIcon
                              style={{
                                fontSize: '10px',
                                color: 'black',
                                marginBottom: '-1px',
                                marginRight: '3px',
                              }}
                            />

                            <span className={` primary-click-text`}>
                              +{el.contactNo}
                            </span>
                          </span>
                          {` | `}
                          <span
                          //onClick={}
                          >
                            <EmailIcon
                              style={{
                                fontSize: '12px',
                                color: 'black',
                                marginBottom: '-3px',
                                marginRight: '3px',
                              }}
                            />
                            <span className={` primary-click-text`}>
                              {el.email}
                            </span>
                          </span>
                        </span>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={e => {
                        handleClick(e, el.id, index, el)
                        setOpenDialog(true)
                      }}
                    >
                      <CreateIcon
                        htmlColor="white"
                        fontSize="small"
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: '-7px',
                          background: theme.palette.primary.main,
                          borderRadius: '3px',
                          color: 'rgba(224,234,254,100)',
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </CardContents>
      </ContentWrapper>
      <Footer
        copyright
        options={[
          {
            name: 'Save',
            onClick: () => {
              handleSubmit(data => onSubmit(data, state, contactData))()
            },
            color: 'primary',
          },
        ]}
      />
      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
          setCheckContactError([])
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Contact Details
                  </span>

                  <span
                    className="title c-orange"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {menu?.obj ? null : 'New'}
                  </span>
                </div>
                <div className="infoline-content">
                  <>
                    <div className="mdLabel flex-space">{menu?.obj?.name}</div>
                    <div className="mdLabel">{menu?.obj ? 'Edit' : null}</div>
                  </>
                </div>
              </div>
            ),
          },
          body: () => (
            <CardContents>
              <Controller
                as={TextField}
                name="Contact.contactName"
                label="Name"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={
                  (watch('Contact.contactName') === '' ||
                    watch('Contact.contactName') === undefined) &&
                  checkContactError?.find(x => x?.error === 'contactName')
                    ? 'Name is required'
                    : null
                }
                error={
                  (watch('Contact.contactName') === '' ||
                    watch('Contact.contactName') === undefined) &&
                  checkContactError?.find(x => x?.error === 'contactName')
                    ? true
                    : false
                }
                ref={register}
                defaultValue={menu?.obj?.name || ''}
              />

              <Controller
                as={TextField}
                name="Contact.designation"
                label="Designation"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={
                  (watch('Contact.designation') === '' ||
                    watch('Contact.designation') === undefined) &&
                  checkContactError?.find(x => x?.error === 'designation')
                    ? 'Designation is required'
                    : null
                }
                error={
                  (watch('Contact.designation') === '' ||
                    watch('Contact.designation') === undefined) &&
                  checkContactError?.find(x => x?.error === 'designation')
                    ? true
                    : false
                }
                ref={register}
                defaultValue={menu?.obj?.designation || ''}
              />
              <Controller
                as={
                  <PhoneNumInput
                    country={user?.countryCode ?? 'my'}
                    specialLabel="Mobile No. *"
                    fullWidth
                  />
                }
                name="Contact.contactNo"
                type="tel"
                marginTop
                marginBottom
                helperText={
                  (watch('Contact.contactNo') === '' ||
                    watch('Contact.contactNo') === undefined) &&
                  checkContactError?.find(x => x?.error === 'contactNo')
                    ? 'Mobile No is required'
                    : null
                }
                error={
                  (watch('Contact.contactNo') === '' ||
                    watch('Contact.contactNo') === undefined) &&
                  checkContactError?.find(x => x?.error === 'contactNo')
                    ? true
                    : false
                }
                margin="normal"
                control={control}
                ref={register}
                defaultValue={menu?.obj?.contactNo || ''}
              />

              <Controller
                as={TextField}
                name="Contact.emailAddress"
                label="Email Address"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                type="email"
                helperText={
                  (watch('Contact.emailAddress') === '' ||
                    watch('Contact.emailAddress') === undefined) &&
                  checkContactError?.find(x => x?.error === 'emailAddress')
                    ? 'Email Address is required'
                    : !!watch('Contact.emailAddress') &&
                      validateEmail(watch('Contact.emailAddress')) === false
                    ? 'Email must be a valid email'
                    : null
                }
                error={
                  (watch('Contact.emailAddress') === '' ||
                    watch('Contact.emailAddress') === undefined) &&
                  checkContactError?.find(x => x?.error === 'emailAddress')
                    ? true
                    : !!watch('Contact.emailAddress') &&
                      validateEmail(watch('Contact.emailAddress')) === false
                    ? true
                    : false
                }
                ref={register}
                defaultValue={menu?.obj?.email || ''}
              />
            </CardContents>
          ),
          leftFooterIcon: menu?.obj ? (
            <span style={{ width: '100%' }}>
              <IconButton
                edge="start"
                style={{
                  color: 'black',
                  marginTop: '-20px',
                  marginBottom: '-15px',
                }}
                onClick={e => {
                  onDeleteContact(menu?.index)
                  setOpenDialog(false)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null,
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setCheckContactError([])
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    menu?.obj
                      ? onUpdateContact(watch('Contact'), menu?.index)
                      : onCreateContact(watch('Contact'))
                    checkContact(watch('Contact'))?.length === 0
                      ? setOpenDialog(false)
                      : setOpenDialog(true)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
