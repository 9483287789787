import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  ListSubheader,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { BigNumber } from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  AccountType,
  RecordStatus,
  useDocumentListingQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetMasterCoaForFormQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import { getTaxRate, getTotalAmt } from '../receipt'
import './Receipt.scss'

interface ReceiptItemProps {
  MasterCOAID: string
  Amount: number
  TaxSchemeID: string
  TaxRate: number
  TaxAmt: number
  DocAmt: number
  Description: string
  CostCentreID: string
  Remark: string
  ReceipttDetail: any
}

export const ReceiptItemForm = (props: any) => {
  const {
    CompanyID,
    BankAccountID,
    ReceiptID,
    ReceiptItemID,
    formMode,
    detailMode,
  }: any = useParams()

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'edit'
      mode = 'edit'
      break
  }
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const itemEditData: any = JSON.parse(
    localStorage.getItem('receiptItem')
  )?.filter(item => item?.ReceiptItemID === ReceiptItemID)[0]

  /* -------------------------------------------- */
  /*                    STATE                     */
  /* -------------------------------------------- */
  const [cost, setCost] = useState(
    amtNumStr(ReceiptItemID && itemEditData?.Amount) ?? 0
  )
  const [TaxRate, setTaxRate] = useState(0)
  const [audioInput, setAudioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [selectedTax, setSelectedTax] = useState(null)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)
  const [accountType, setAccountType] = useState('')
  const [voiceRemark, setVoiceRemark] = useState('')

  //validation
  const ReceiptItemFormSchema = yup.object().shape({
    MasterCOAID: CommonYupValidation.requireField(
      'Revenue Account is required'
    ),
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    Amount: CommonYupValidation.requireField('Amount is required'),
  })

  const styles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },

    item: {
      padding: '0',
      paddingRight: '10px',
      borderBottom: '2px solid rgb(224,224,224)',
    },
    lastItem: {
      padding: '0',
      paddingRight: '10px',
    },
    deleteIcon: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
    list: {
      width: '100%',
      // paddingBottom: '25px',
      [theme.breakpoints.up('md')]: {
        width: '80%',
        margin: 'Auto',
      },
    },
  }))

  //Company
  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onCompleted: data => {
      loadDefaultCostCentre(data?.getCompany[0]?.DefaultCostCentre)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrDialog(true)
    },
  })
  useEffect(() => {
    if (ReceiptItemID && itemEditData?.Remark) {
      setAudioInput(ReceiptItemID && itemEditData?.Remark)
    }
  }, [itemEditData])
  // Tax
  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {
      if (detailMode === 'edit') {
        setSelectedTax(
          taxScheme?.find(x => x?.TaxSchemeID === itemEditData?.TaxSchemeID)
        )
        setTaxRate(
          taxScheme
            .filter(x => x?.TaxSchemeID === itemEditData?.TaxSchemeID)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrDialog(true)
    },
    variables: {
      RecordStatus: RecordStatus.Active,
      AccTaxClass: 'OUTPUT',
    },
  })

  //EntityCOA
  const {
    loading: MasterCOALoading,
    data: { getMasterCOAForForm } = { getMasterCOAForForm: [] },
  } = useGetMasterCoaForFormQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getMasterCOAForForm }) => {
      if (getMasterCOAForForm?.length > 0) {
        if (!!itemEditData) {
          const findcoa = getMasterCOAForForm?.find(
            x => x?.MasterCOAID === itemEditData?.MasterCOAID
          )
          setAccountType(findcoa?.AccountType)
        }
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrDialog(true)
    },
  })

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
  })

  // Change Tax descrition into code number

  const [totalAmt, setTotalAmt] = useState(0.0)
  const [amount, setAmount] = useState(0.0)
  const [taxAmt, setTaxAmt] = useState(
    !!itemEditData && ReceiptItemID ? itemEditData?.TaxAmt : 0
  )
  const taxScheme: any = getTaxScheme

  // change tax
  const handleTaxChange = (event, taxEffectiveDate) => {
    setSelectedTax(taxScheme.find(x => x?.TaxSchemeID === event?.target?.value))
    setTaxRate(
      taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
        .LatestTax === null
        ? taxScheme
            .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
            ?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        : taxScheme
            .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
    )
    setTaxAmt(
      new BigNumber(
        taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
          .LatestTax === null
          ? taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
          : taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective?.filter(
                x => new Date(x?.Date) <= new Date(taxEffectiveDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
      )
        .dividedBy(100)
        .multipliedBy(cost)
        .toNumber()
    )
  }

  // Function//
  const handleDefaultCostCentre = (accType, coaId) => {
    setAccountType(accType)
    if (
      accType === 'ASSET' ||
      accType === 'LIABILITY' ||
      accType === 'EQUITY'
    ) {
      setValue('CostCentreID', defaultCostCentre?.CostCentreID)
    } else {
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: coaId,
          IsLastNode: true,
        },
      })
    }
  }

  const handleAmtChange = event => {
    setCost(event.value)

    setTaxAmt(
      new BigNumber(
        selectedTax?.LatestTax === null
          ? selectedTax?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
          : selectedTax?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            ).reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
      )
        .dividedBy(100)
        .multipliedBy(event.value)
        .toNumber()
    )
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }

  const calculateTotalAmt = () => {
    setTotalAmt(amount + amount * (TaxRate / 100))
  }

  /* -------------------------------------------- */
  /*                   USE EFFECT                 */
  /* -------------------------------------------- */

  useEffect(() => {
    if (ReceiptItemID && itemEditData?.Remark) {
      setAudioInput(ReceiptItemID && itemEditData?.Remark)
      setValue('Remark', itemEditData?.Remark)
      if (getMasterCOAForForm?.length > 0) {
        const findcoa = getMasterCOAForForm?.find(
          x => x?.MasterCOAID === itemEditData?.MasterCOAID
        )[0]

        handleDefaultCostCentre(findcoa?.AccountType, findcoa?.MasterCOAID)
      }
    }
  }, [])

  useEffect(() => {
    if (getMasterCOAForForm?.length > 0 && itemEditData) {
      const findcoa = getMasterCOAForForm?.find(
        x => x?.MasterCOAID === itemEditData?.MasterCOAID
      )

      handleDefaultCostCentre(findcoa?.AccountType, findcoa?.MasterCOAID)
    }
  }, [getMasterCOAForForm])

  // Autoset tax after select tax amount
  useEffect(() => {
    if (!!selectedTax) {
      setValue('TaxSchemeID', selectedTax?.TaxSchemeID)
      clearErrors('TaxSchemeID')
    }
  }, [selectedTax])

  useEffect(() => {
    if (ReceiptItemID && itemEditData?.Remark) {
      setValue('Remark', itemEditData?.Remark)
    }
  }, [itemEditData])

  // change tax amount(controller) *taxAmtChangeableNeeded only
  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValue('TaxAmt', taxAmt)
      clearErrors('TaxAmt')
    }
  }, [taxAmt])

  useEffect(() => {
    calculateTotalAmt()
  }, [amount, TaxRate])

  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date(
    new Date(JSON.parse(localStorage.getItem('receipt'))?.DocDate).getTime() +
      24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxSchemeOutput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const {
    loading: docLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: itemEditData?.ReceiptID },
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm<ReceiptItemProps>({
    defaultValues: {
      MasterCOAID:
        ReceiptItemID && itemEditData ? itemEditData?.MasterCOAID : '',
      Amount: ReceiptItemID && itemEditData ? itemEditData?.Amount : '',
      TaxSchemeID:
        ReceiptItemID && itemEditData ? itemEditData?.TaxSchemeID : '',
      TaxRate: ReceiptItemID && itemEditData ? itemEditData?.TaxRate : '',
      TaxAmt: ReceiptItemID && itemEditData ? itemEditData?.TaxAmt : '',
      DocAmt: ReceiptItemID && itemEditData ? itemEditData?.DocAmt : '',
      CostCentreID:
        ReceiptItemID && itemEditData ? itemEditData?.CostCentreID : '',
      Remark: ReceiptItemID && itemEditData ? itemEditData?.Remark : '',
    },
    mode: 'onSubmit',

    resolver: yupResolver(ReceiptItemFormSchema),
  })

  // Mutation Create & Update Receipt

  // Save to local storage
  const onSubmit = data => {
    const prvdetail =
      detailMode === 'add'
        ? JSON.parse(localStorage.getItem('receiptItem')) || []
        : JSON.parse(localStorage.getItem('receiptItem')).filter(
            x => x?.ReceiptItemID !== ReceiptItemID
          )

    localStorage.setItem(
      'receiptItem',
      JSON.stringify([
        ...(prvdetail?.length === null || 0 ? null : prvdetail),

        {
          Sequence: itemEditData?.Sequence ?? prvdetail?.length + 1,
          ReceiptItemID: detailMode === 'add' ? uuid() : ReceiptItemID,
          MasterCOAID:
            formMode === 'add' || formMode === 'edit'
              ? data.MasterCOAID
              : itemEditData?.MasterCOAID,
          RevenueCode:
            formMode === 'add' || formMode === 'edit'
              ? getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === data.MasterCOAID
                )[0]?.Code
              : getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.Code,
          RevenueName:
            formMode === 'add' || formMode === 'edit'
              ? getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === data.MasterCOAID
                )[0]?.Name
              : getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.Name,
          Amount:
            formMode === 'add' || formMode === 'edit'
              ? parseFloat(amtNumStr(data.Amount))
              : itemEditData?.Amount,
          TaxSchemeID:
            formMode === 'add' || formMode === 'edit'
              ? data.TaxSchemeID
              : itemEditData?.TaxSchemeID,
          TaxCode:
            formMode === 'add' || formMode === 'edit'
              ? getTaxScheme.filter(
                  taxcode => taxcode?.TaxSchemeID === data.TaxSchemeID
                )[0]?.Code
              : getTaxScheme.filter(
                  taxcode => taxcode?.TaxSchemeID === itemEditData?.TaxSchemeID
                )[0]?.Code,
          TaxRate:
            formMode === 'add' || formMode === 'edit'
              ? getTaxRate(TaxRate)
              : getTaxScheme?.find(
                  x => x?.TaxSchemeID === itemEditData?.TaxSchemeID
                )?.LatestTax?.Rate,
          TaxAmt:
            formMode === 'add' || formMode === 'edit'
              ? parseFloat(data.TaxAmt)
              : itemEditData?.TaxAmt,
          DocAmt:
            formMode === 'add' || formMode === 'edit'
              ? getTotalAmt(true, cost, taxAmt, TaxRate)
              : itemEditData?.DocAmt,
          CostCentreID: data.CostCentreID,
          CostCentreCode:
            getCostCentre.filter(
              ccc => ccc?.CostCentreID === data.CostCentreID
            )[0]?.Code ?? defaultCostCentre.Code,
          Remark: data.Remark,
        },
      ])
    )
    if (
      (formMode === 'add' && detailMode === 'add') ||
      (formMode === 'add' && detailMode === 'edit')
    ) {
      history.push({
        pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${formMode}`,
        state: { ...editData },
      })
    } else if (
      (formMode === 'edit' && detailMode === 'edit') ||
      (formMode === 'edit' && detailMode === 'add') ||
      formMode === 'approve-reject'
    ) {
      history.push({
        pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${ReceiptID}/${formMode}`,
        state: { ...editData, detailMode: 'add' },
      })
    }
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: itemEditData,
      formMode: detailMode,
    })

  return (
    <>
      {docLoading && <Loading />}
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {/* <SnackBarMsg open={msg} message={MESSAGE} /> */}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (
            (formMode === 'add' && detailMode === 'add') ||
            (formMode === 'add' && detailMode === 'edit')
          ) {
            // hasChanges() === true
            //   ? setOpenExitConf(true)
            //   :
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${formMode}`,
              state: { ...editData },
            })
          } else if (
            (formMode === 'edit' && detailMode === 'edit') ||
            (formMode === 'edit' && detailMode === 'add') ||
            formMode === 'approve-reject'
          ) {
            // hasChanges() === true
            //   ? setOpenExitConf(true)
            //   :
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${ReceiptID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }

          // history.goBack()
          // JSON.parse(localStorage.getItem('receipt'))
        }}
        smTitle={'Cash Book'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Cash Book' },
          { name: 'Receipt' },
          { name: 'Receipt', current: true },
        ]}
        rightRouteSegments={[
          { name: detailMode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />

      <ContentWrapper float>
        <CardContents
          // IsDifferentColour={true}
          section={{
            header: {
              title: 'Receipt Detail',
            },
          }}
        >
          {!MasterCOALoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getMasterCOAForForm?.filter(
                  x => x?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]
                return (
                  <Autocomplete
                    options={getMasterCOAForForm || []}
                    getOptionLabel={option =>
                      `${option?.Code} | ${option?.Name}`
                    }
                    fullWidth
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.MasterCOAID)
                      setValue('CostCentreID', null)
                      handleDefaultCostCentre(
                        newValue?.AccountType,
                        newValue?.MasterCOAID
                      )
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    PopperComponent={AccCodeDropdownFullWidth}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">{props?.Code}</span>
                          </div>
                          <div className="desc">{props?.Name}</div>
                        </div>
                      )
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.MasterCOAID?.message}
                            error={errors?.MasterCOAID ? true : false}
                            label="Revenue/Income Account"
                            required
                            style={{ width: '100%' }}
                            disabled={mode === 'approve-reject'}
                            margin="normal"
                            variant="standard"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Revenue/Income Account"
              name="MasterCOAID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.MasterCOAID?.message}
              error={errors?.MasterCOAID ? true : false}
              defaultValue={
                getMasterCOAForForm?.filter(
                  x => x?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.MasterCOAID
              }
              required
              disabled={formMode === 'approve-reject' ? true : false}
            />
          )}

          {accountType === 'ASSET' ||
          accountType === 'LIABILITY' ||
          accountType === 'EQUITY' ? (
            <Controller
              render={({ value, onChange }) => {
                return (
                  <TextField
                    value={`${defaultCostCentre?.Code} | ${defaultCostCentre?.Name}`}
                    label="Department *"
                    fullWidth
                    disabled
                  />
                )
              }}
              label="Department *"
              name="CostCentreID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CostCentreID?.message}
              error={errors?.CostCentreID ? true : false}
              required
              disabled={formMode === 'approve-reject' ? true : false}
            />
          ) : (
            !CostCentreLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const editCC = getCostCentre?.filter(
                    cc =>
                      cc?.CostCentreID === itemEditData?.CostCentreID ??
                      watch('CostCentreID')
                  )[0]
                  const selectedCC = getCostCentre?.filter(
                    cc => cc?.CostCentreID === value
                  )[0]
                  return (
                    <Autocomplete
                      options={getCostCentre || []}
                      getOptionLabel={option =>
                        option ? `${option?.Code} | ${option?.Name}` : ''
                      }
                      fullWidth
                      onChange={(value, newValue: any) => {
                        onChange(newValue?.CostCentreID)
                      }}
                      disabled={mode === 'approve-reject'}
                      PopperComponent={AccCodeDropdownFullWidth}
                      //value={costCentreName}
                      renderOption={(props, option) => {
                        return (
                          <div>
                            <div>
                              <span className="xsTitle">{props?.Code}</span>
                            </div>
                            <div className="desc">{props?.Name}</div>
                          </div>
                        )
                      }}
                      renderInput={(params: any) => {
                        if (detailMode === 'edit') {
                          params.inputProps.value = `${editCC?.Code} | ${editCC?.Name}`
                        }

                        return (
                          <div>
                            <TextField
                              {...params}
                              value={
                                selectedCC
                                  ? `${selectedCC?.Code} | ${selectedCC?.Name}`
                                  : null
                              }
                              defaultValue={
                                editCC
                                  ? `${editCC?.Code} | ${editCC?.Name}`
                                  : null
                              }
                              helperText={errors?.CostCentreID?.message}
                              error={errors?.CostCentreID ? true : false}
                              label="Department *"
                              style={{ width: '100%' }}
                              disabled={mode === 'approve-reject'}
                              InputLabelProps={
                                selectedCC ? { shrink: true } : null
                              }
                              margin="normal"
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="Department *"
                name="CostCentreID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="normal"
                ref={register}
                helperText={errors?.CostCentreID?.message}
                error={errors?.CostCentreID ? true : false}
                required
                disabled={formMode === 'approve-reject' ? true : false}
                defaultValue={
                  itemEditData?.CostCentreID ? itemEditData?.CostCentreID : null
                }
              />
            )
          )}

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="Amount"
            label="Amount"
            value={cost}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="dense"
            required
            helperText={errors?.Amount?.message}
            error={errors?.Amount ? true : false}
            ref={register}
            defaultValue={ReceiptItemID ? itemEditData?.Amount : null}
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <Controller
            as={
              <TextField
                className="left"
                margin="dense"
                label="Tax Code"
                required
                SelectProps={{
                  renderValue: () => `${selectedTax?.Code}`,
                  onChange: event => {
                    handleTaxChange(event, taxEffectiveDate)
                    setValue('TaxSchemeID', event?.target?.value.toString())
                    clearErrors('TaxSchemeID')
                  },
                }}
                select
              >
                <ListSubheader>Output</ListSubheader>
                {taxSchemeOutput?.map((tax, index) => (
                  <MenuItem
                    id="tax-select"
                    key={index}
                    value={tax?.TaxSchemeID}
                  >
                    <span className="text-noflow">{`${tax?.Code}`}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            select
            name="TaxSchemeID"
            autoComplete="off"
            control={control}
            fullWidth
            ref={register}
            helperText={errors?.TaxSchemeID?.message}
            error={errors?.TaxSchemeID ? true : false}
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <TextField
            name="TaxRate"
            label="Tax Rate %"
            value={amtStr(getTaxRate(TaxRate))}
            disabled
            className="right"
            margin="normal"
            ref={register}
            style={{ marginTop: '5px' }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="normal"
            name="TaxAmt"
            label="Tax Amount"
            disabled={formMode === 'approve-reject' ? true : false}
            onValueChange={e => {
              handleTaxAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            className="left"
            required
            // helperText={errors?.TaxAmt?.message}
            // error={errors?.TaxAmt ? true : false}
          />

          <TextField
            name="DocAmt"
            variant="outlined"
            label="Document Amount"
            value={amtStr(getTotalAmt(true, cost, taxAmt, TaxRate))}
            InputProps={{
              style: {
                color: '#96938e',
                //fontSize: '14px',
                backgroundColor: 'white',
              },
            }}
            className="outlined-disabled right"
            disabled
            margin="normal"
            ref={register}
            style={{ marginTop: '25px', color: 'white' }}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
        </CardContents>
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          if (formMode === 'add')
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${formMode}`,
              state: { ...editData },
            })
          else {
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${ReceiptID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }
        }}
      />
      <AccountFooter
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },
            props: { type: 'submit' },
          },
        ]}
      />
    </>
  )
}
function setErrDialog(arg0: boolean) {
  throw new Error('Function not implemented.')
}
