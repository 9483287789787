import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  useMediaQuery,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  useGetClassificationCodeQuery,
  useGetUomCodeQuery,
} from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'

export const ItemDetail = (props: any) => {
  const {
    CN = false,
    titleLabel,
    itemData,
    setOpenDialog,
    fetchExpenseItem,
    fetchCostCentre,
    resetMenu,
    setSelectedTax,
    setDetailMode,
    formMode,
    detailMode,
    getItemByCompany,
    getCostCentre,
    handleClick,
    keyItemID,
    isSelfBilled,
    creditorID,
  } = props

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  // UOM
  const {
    loading: UOMLoading,
    data: { getUOM } = { getUOM: [] },
  } = useGetUomCodeQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  // UOM
  const {
    loading: ClassificationCodeLoading,
    data: { getClassificationCode } = { getClassificationCode: [] },
  } = useGetClassificationCodeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const handleAddItem = () => {
    if (creditorID?.length === 0) {
      setErrorDia(true)
      setErrMsg('Please select creditor first.')
    } else {
      setOpenDialog(true)
      fetchExpenseItem()
      fetchCostCentre()
      resetMenu()
      setSelectedTax(null)
      setDetailMode('add')
    }
  }

  return (
    <>
      {UOMLoading && <Loading />}
      {ClassificationCodeLoading && <Loading />}
      <CardContents
        section={{
          header: {
            title: CN
              ? `${titleLabel} (${itemData?.length})`
              : `${titleLabel} Detail (${itemData?.length})`,
            onClickAction: () => handleAddItem(),
            icon: (
              <AddIcon
                htmlColor="white"
                fontSize="small"
                style={{
                  width: '20px',
                  height: '20px',
                  margin: '0',
                  background: theme.palette.primary.main,
                  borderRadius: '3px',
                  color: 'rgba(224,234,254,100)',
                  marginTop: '10px',
                  marginRight: '10px',
                }}
              />
            ),
          },
        }}
      >
        <div className="rm-padding table-wrap ">
          {itemData === undefined || itemData?.length === 0 ? (
            <EmptyList title="No Record Found" subtitle="Add New Record now." />
          ) : (
            itemData
              ?.sort((a, b) => {
                return a?.Sequence < b?.Sequence ? -1 : 1
              })
              ?.map((v, index) => {
                return (
                  <>
                    <List
                      className="item-list content-wrap full text-noflow"
                      style={{ paddingTop: '0', paddingBottom: '0' }}
                    >
                      <ListItem>
                        <Grid spacing={1} container className="table-content">
                          <Grid item xs={1} style={{ placeSelf: 'start' }}>
                            <span className="xxTitle">{`${index + 1}.`}</span>
                          </Grid>

                          <Grid
                            item
                            xs={7}
                            style={{ placeSelf: 'start', marginTop: '8px' }}
                          >
                            <div
                              className="xxTitle text-noflow"
                              style={{ lineBreak: 'anywhere' }}
                            >
                              {formMode === 'add' ||
                              (formMode === 'edit' && detailMode === 'add')
                                ? getItemByCompany?.find(
                                    x => x?.ExpenseItemID === v?.ExpenseItemID
                                  )?.ItemName ?? v?.ExpenseItemName
                                : v?.ExpenseItemName}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            style={{
                              placeSelf: 'start',
                              justifyContent: isDesktop ? 'center' : 'end',
                              marginTop: '8px',
                            }}
                          >
                            <div
                              className="xxTitle"
                              style={{ textAlign: 'right' }}
                            >
                              {amtStr(v?.DocAmt)}
                            </div>
                          </Grid>

                          <Grid item xs={1} style={{ placeSelf: 'start' }} />
                          <Grid
                            item
                            xs={7}
                            style={{ placeSelf: 'start', marginTop: '-10px' }}
                          >
                            <div className="desc text-noflow">
                              {formMode === 'add'
                                ? getCostCentre?.find(
                                    x => x?.CostCentreID === v?.CostCentreID
                                  )?.Code ?? v?.CostCentreCode
                                : v?.CostCentreCode}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            style={{ placeSelf: 'start', marginTop: '-10px' }}
                          >
                            <div
                              className="desc"
                              style={{ textAlign: 'right' }}
                            >
                              {amtStr(v?.Amount) || amtStr(v?.BaseAmt)}
                            </div>
                          </Grid>

                          <Grid item xs={1} style={{ placeSelf: 'start' }} />
                          <Grid
                            item
                            xs={4}
                            style={{ placeSelf: 'start', marginTop: '-8px' }}
                          >
                            <div className="desc text-noflow">
                              {v?.Quantity}{' '}
                              {getUOM?.find(x => x?.UOMID === v?.UOMID)?.Code ??
                                v?.UOMCode}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            style={{ placeSelf: 'start', marginTop: '-8px' }}
                          >
                            <div className="desc">
                              {`${v?.TaxCode} ${parseInt(v?.TaxRate)}%`}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            style={{ placeSelf: 'end', marginTop: '-8px' }}
                          >
                            <div
                              className="desc"
                              style={{ textAlign: 'right' }}
                            >
                              {amtStr(v?.TaxAmt)}
                            </div>
                          </Grid>

                          {isSelfBilled && (
                            <>
                              <Grid
                                item
                                xs={1}
                                style={{ placeSelf: 'start' }}
                              />
                              <Grid
                                item
                                xs={11}
                                style={{
                                  placeSelf: 'start',
                                  marginTop: '-8px',
                                }}
                              >
                                <div className="desc text-noflow">
                                  {`${getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      v?.einvoice_classification_code_id
                                  )[0]?.classificationCode ??
                                    v?.ClassificationCode} (${getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      v?.einvoice_classification_code_id
                                  )[0]?.description ??
                                    v?.ClassificationDescription})` ?? ''}
                                </div>
                              </Grid>
                            </>
                          )}

                          <Grid item xs={1} style={{ placeSelf: 'start' }} />
                          <Grid
                            item
                            xs={11}
                            style={{ placeSelf: 'start', marginTop: '-8px' }}
                          >
                            <div className="desc text-noflow">{v?.Remark}</div>
                          </Grid>
                        </Grid>

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e => {
                              handleClick(e, v[`${keyItemID}`], index, v)
                            }}
                            style={{ padding: '8px' }}
                          >
                            <MoreVert />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </>
                )
              })
          )}
        </div>
      </CardContents>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
