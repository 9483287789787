import {
  useGetApInvoiceLazyQuery,
  useGetTransactionTypeQuery,
} from '@account-root/account-react/src/generated/graphql';
import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import { Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ErrorDialog } from 'components/Dialog/ErrorDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { TooltipAmtFooter } from '../../../components/Footer/TooltipAmtFooter';
import {
  ApprovalStatus,
  GetGeneralDoDocument,
  GetGrtnHeaderDocument,
  useCreateGrtnMutation,
  useDocumentListingLazyQuery,
  useGetDeliveryLocationNameQuery,
  useGetGeneralDOsListByIDsLazyQuery,
  useGetGeneralDoFormLazyQuery,
  useGetGeneralPurchasePolicyQuery,
  useGetItemByCompanyAssignmentQuery,
  useGetSupplierByDoStatusQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateGrtnMutation,
} from '../../../generated/graphql';
import { SystemMsgs } from '../../../helpers/Messages/SystemMsg';
import { ExitConfirmationDialog } from '../components/ExitConfirmationDialog';
import { GeneralGRTNContentListing } from './Components/GeneralGRTNContentListing';

interface GoodReturnNoteProps {
  DocDate: Date;
  TrxDate: Date;
  SupplierID: string;
  DocRef: string;
  Description: string;
  Attachment: string;
  LocationID: string;
  getDO: any[];
}

export const GRTNSchema = yup.object().shape({
  SupplierID: CommonYupValidation.requireField(SystemMsgs.supplierSelection()),
  Description: CommonYupValidation.requireField(SystemMsgs.description()),
  LocationID: CommonYupValidation.requireField(SystemMsgs.deliveryLocation()),
  DocRef: CommonYupValidation.requireField(SystemMsgs.docRef()),
  docDate: CommonYupValidation.requireField('Doc Date is required'),
  trxDate: CommonYupValidation.requireField('Transaction Date is required'),
});

export const GeneralGRTNForm = ({ mode }) => {
  let formMode = mode === 'add' ? 'New' : 'Edit';
  let history = useHistory();
  let location = useLocation();
  const editData = location?.state as any;
  const { CompanyID, GRTNHeaderID }: any = useParams();
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [noError, setNoError] = useState(true);
  const [doDate, setDoDate] = useState(new Date());
  const [itemHasValue, setItemHasValue] = useState(null);
  const [itemHasValueAmt, setItemHasValueAmt] = useState(null);
  const [exitDialog, setExitDialog] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const [errDialog, setErrDialog] = useState(false);
  const [selectedDO, setSelectedDO] = useState(new Set());
  const [tempSelectedDO, setTempSelectedDO] = useState(new Set());

  const [doFormArray, setDoFormArray] = useState([]);
  const [doHeaderArray, setDoHeaderArray] = useState([]);

  const sortingFormatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formattedDate = sortingFormatDate(doDate);

  const handleCheckboxDOChange = (event: any) => {
    if (event?.target?.checked) {
      tempSelectedDO.add(event?.target?.value);
    } else {
      tempSelectedDO.delete(event?.target?.value);
    }
  };

  const editResubmitMode = mode => {
    switch (mode) {
      case 'edit':
        return true;
      case 'resubmit':
        return true;
      default:
        return false;
    }
  };

  const [suppID, setSuppID] = useState(
    editResubmitMode(mode) ? editData?.SupplierID : null,
  );
  const [LocationID, setLocationID] = useState(
    editResubmitMode(mode) ? editData?.DeliveryLocationID : null,
  );

  const [selectedDate, handleDateChange] = useState(
    editResubmitMode(mode) ? new Date(editData?.DocDate) : new Date(),
  );

  //==============================================//
  //-------------------USEFORM--------------------//
  //==============================================//
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState,
    getValues,
  } = useForm<GoodReturnNoteProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(GRTNSchema),
  });

  //============================================//
  //-------------------QUERY--------------------//
  //============================================//
  const [
    loadDOHeader,
    { loading: DOHeaderLoading, data: { getGeneralDO } = { getGeneralDO: [] } },
  ] = useGetGeneralDoFormLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: ({ getGeneralDO }) => {
      setDoFormArray([]);

      let temp = getGeneralDO
        ?.filter(
          m =>
            m?.DOItem?.filter(
              q =>
                !(
                  (formMode === 'add'
                    ? q?.UpToDateQty === 0
                    : q?.AcceptedQty === 0)
                  // &&
                  // (editData?.GRTNHeaderItem?.map(x => x?.ReturnedQty) ?? 0) ===
                  //   0
                ),
            )?.length > 0,
        )
        ?.filter(dateFil => dateFil?.DocDate <= formattedDate)
        ?.filter(
          A => !getAPInvoice?.some(C => C?.DO?.DOHeaderID === A?.DOHeaderID),
        )
        ?.sort((a, b) => {
          return a.DocNo.localeCompare(b.DocNo);
        });

      setDoFormArray(x => [...x, ...temp]);
    },
  });

  const [
    loadDOsList,
    {
      loading: DOHeaderListLoading,
      called: doHeaderListCalled,
      data: { getGeneralDOsListByIDs } = { getGeneralDOsListByIDs: [] },
    },
  ] = useGetGeneralDOsListByIDsLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: ({ getGeneralDOsListByIDs }) => {
      setDoHeaderArray(x => [...x, ...getGeneralDOsListByIDs]);

      if (editData) {
        getGeneralDOsListByIDs?.map(dol => {
          dol?.DOItem?.map((item, index) => {
            register(`getDO[${item?.DOHeaderID}].DOItem[${index}].ReturnedQty`);
            register(`getDO[${item?.DOHeaderID}].DOItem[${index}].ReturnedAmt`);

            setValue(
              `getDO[${item?.DOHeaderID}].DOItem[${index}].ReturnedQty`,
              editData?.GRTNHeaderItem?.find(
                x => x?.DOItemID === item?.DOItemID,
              )?.ReturnedQty,
            );
            setValue(
              `getDO[${item?.DOHeaderID}].DOItem[${index}].ReturnedAmt`,
              editData?.GRTNHeaderItem?.find(
                x => x?.DOItemID === item?.DOItemID,
              )?.ReturnedAmt,
            );
          });
        });
      }
    },
  });

  const [
    loadAPInvoice,
    {
      loading: APInvoiceLoading,
      data: { getAPInvoice } = { getAPInvoice: [] },
    },
  ] = useGetApInvoiceLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  useEffect(() => {
    // Only fetch AP invoices if CompanyID and suppID are available
    if (CompanyID && suppID) {
      loadAPInvoice({
        variables: {
          CompanyID: CompanyID,
          CreditorAccountID: suppID,
        },
      });
    }
  }, [CompanyID, suppID]);

  const filteredGeneralDO = doHeaderArray?.filter(
    A => !getAPInvoice?.some(C => C?.DO?.DOHeaderID === A?.DOHeaderID),
  );

  const {
    loading: SupplierLoading,
    data: { getSupplierByDOStatus } = { getSupplierByDOStatus: [] },
  } = useGetSupplierByDoStatusQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      ApprovalStatus: ApprovalStatus.Completed,
    },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const {
    loading: WarehouseLoading,
    data: { getDeliveryLocation } = {
      getDeliveryLocation: [],
    },
  } = useGetDeliveryLocationNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const {
    loading: OpenPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const {
    loading: ItemByCompanyAssignmentLoading,
    data: { getItemByCompanyAssignment } = {
      getItemByCompanyAssignment: [],
    },
  } = useGetItemByCompanyAssignmentQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name', CompanyID: CompanyID, IsDisallow: false },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const {
    loading: GPPolicyLoading,
    error,
    data: { getGeneralPurchasePolicy } = { getGeneralPurchasePolicy: [] },
  } = useGetGeneralPurchasePolicyQuery({
    fetchPolicy: 'network-only',
    variables: { AccountID: user?.accountID },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const {
    loading: TransactionTypeLoading,
    error: TransactionTypeError,
    data: { getTransactionType } = { getTransactionType: [] },
  } = useGetTransactionTypeQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: () => {
      let doHeaderIdArray: string[] = [];
      let selected = selectedDO;
      if (!!editData) {
        editData?.GRTNHeaderItem?.map(x => {
          !doHeaderIdArray?.includes(x?.DOItem?.DOHeader?.DOHeaderID) &&
            doHeaderIdArray?.push(x?.DOItem?.DOHeader?.DOHeaderID) &&
            selected.add(x?.DOItem?.DOHeader?.DOHeaderID);
        });
      }

      if (doHeaderIdArray?.length > 0) {
        loadDOsList({
          variables: {
            DOHeaderID: doHeaderIdArray,
            CompanyID: CompanyID,
            SupplierID: editData?.SupplierID,
          },
        });
      }

      if (selected?.size > 0) {
        setSelectedDO(new Set(selected));
      }
    },
  });

  const grnTransaction = getTransactionType?.filter(
    x => x?.Name === 'Goods Receive Note',
  );

  const [gpPolicyControl, setGPPolicyControl] = useState<boolean>(false);

  const checkGPPolicy = () => {
    if (getGeneralPurchasePolicy[0]?.MasterCOAID?.length > 0) {
      return true;
    } else return false;
  };

  const [icTrxType, setICTrxType] = useState<boolean>(false);
  const checkICTrxType = () => {
    if (grnTransaction[0]?.MasterCOAID?.length > 0) {
      return true;
    } else return false;
  };

  //============================================//
  //----------------ATTACHMENT------------------//
  //============================================//
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
    setPreviewFiles,
  } = useUploadAttachment();

  const [
    fetchDocuments,
    { loading: DocumentLoading, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { refID: GRTNHeaderID },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf'),
        );
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf',
          )?.map(x => x?.fileURL),
        );
      }
    },
  });

  //============================================//
  //------------------MUTATION------------------//
  //============================================//
  const [CreateGRTN, { loading: CreateLoading }] = useCreateGrtnMutation({
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: ({ CreateGRTN }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push(
            `/general-purchase/${CompanyID}/good-return-note/${CreateGRTN?.GRTNHeaderID}/detail/preview`,
          );
        } else {
          history.push(`/general-purchase/${CompanyID}/good-return-note`);
        }
      }, 500);
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
    },
  });

  const [UpdateGRTN, { loading: UpdateLoading }] = useUpdateGrtnMutation({
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: ({ UpdateGRTN }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push(
            `/general-purchase/${CompanyID}/good-return-note/${UpdateGRTN?.GRTNHeaderID}/detail/preview`,
          );
        } else {
          history.push(`/general-purchase/${CompanyID}/good-return-note`);
        }
      }, 500);
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
    },
  });

  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ -  USE EFFECT  - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥//
  useEffect(() => {
    if (mode === 'edit')
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: GRTNHeaderID,
          },
        });
      }
  }, [mode]);

  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files);
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file);
        }),
      );
    }
  }, [editData]);

  useEffect(() => {
    setDoDate(watch('docDate'));
  }, [watch('docDate')]);

  //============================================//
  //------------------ONSUBMIT------------------//
  //============================================//
  const createUpdateGRTN = mode === 'add' ? CreateGRTN : UpdateGRTN;

  const onSubmit = (data, submitMode) => {
    let getDO = watch('getDO');

    createUpdateGRTN({
      variables: {
        GRTNHeaderID: mode === 'edit' ? GRTNHeaderID : null,
        grtnInput: {
          SupplierID: data?.SupplierID,
          CompanyID: CompanyID,
          DeliveryLocationID: data?.LocationID,
          WarehouseID: null,
          DocDate: new Date(data?.docDate).toISOString(),
          TransactionDate: new Date(data?.trxDate).toISOString(),
          RefNo: data?.DocRef,
          Description: data?.Description,
          file: files,
          ApprovalStatus:
            submitMode === 'submit'
              ? ApprovalStatus.Submit
              : submitMode === 'reject'
              ? ApprovalStatus.Rejected
              : ApprovalStatus.Active,
        },
        grtnItemInput: Object.values(getDO)
          ?.map((x: any) => {
            return x?.DOItem.map(y => {
              return {
                DoItemID: y?.doItemID,
                ReturnedQty: parseFloat(y?.ReturnedQty ?? 0),
                ReturnedAmt: parseFloat(y?.ReturnedAmt ?? 0),
                Replacement: y?.replacement === true ? true : false,
              };
            });
          })
          .flat(),
      },
      refetchQueries: [
        {
          query: GetGrtnHeaderDocument,
          variables: {
            CompanyID: CompanyID,
            orderByDesc: 'DocDate',
            skip: 0,
            take: 30,
          },
        },
        {
          query: GetGeneralDoDocument,
          variables: {
            CompanyID: CompanyID,
            ApprovalStatus: ApprovalStatus.Active,
            skip: 0,
            take: 30,
          },
        },
      ],
    });
  };

  const handleCLick = handleSubmit(onSubmit);

  const checkItemQty = () => {
    const acceptedQtys = [];

    getGeneralDOsListByIDs?.map((el, inindex) => {
      el?.DOItem?.map((inel, initem) => {
        const acceptedAmt = watch(
          `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
        ) as string | undefined;

        const acceptedQty = Number(acceptedAmt);
        if (!isNaN(acceptedQty)) {
          acceptedQtys.push(acceptedQty);
        }
      });
    });

    const totalAcceptedQty = acceptedQtys.reduce((acc, qty) => acc + qty, 0);

    return totalAcceptedQty;
  };

  //============================================//
  //------------------CHECKING------------------//
  //============================================//

  const doItemsArr = getGeneralDOsListByIDs?.filter(
    ({ DocDate }) => new Date(DocDate) < new Date(selectedDate),
  );

  const checkItemLength = () => {
    let status = false;

    getGeneralDOsListByIDs?.forEach(el => {
      el?.DOItem?.forEach((initem, index) => {
        // Construct the path correctly using index
        const path = `getDO[${el?.DOHeaderID}].DOItem[${index}].ReturnedQty`;
        const ReturnedQty = watch(path);
        if (ReturnedQty !== undefined && ReturnedQty !== null) {
          const ReturnedQtyString = String(ReturnedQty).replace(/,/g, '');
          const ReturnedQtyNumber = parseFloat(ReturnedQtyString);

          if (
            mode === 'add'
              ? ReturnedQtyNumber > 0
              : ReturnedQtyNumber > 0 || ReturnedQty === undefined
          ) {
            status = true;
          }
        }
      });
    });

    return status;
  };

  const checkGRTNItem = () => {
    let status = false;
    getGeneralDOsListByIDs?.map((el, inindex) => {
      el?.DOItem?.map((inel, initem) => {
        const watchRtQty = watch(
          `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
        );
        const ReturnedQty =
          typeof watchRtQty === 'string' && watchRtQty.includes(',')
            ? watchRtQty.replace(/,/g, '')
            : watchRtQty;

        const DOqty =
          mode === 'add'
            ? inel?.UpToDateQty
            : inel?.UpToDateQty + inel?.ReturnedQty;

        if ((ReturnedQty as number) > DOqty) {
          status = true;
        }
      });
    });
    return status;
  };

  const trxDateTimestamp = new Date(watch('trxDate'));
  const yearTrxDate = trxDateTimestamp.getFullYear();
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0');
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0');

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`;

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate;

  const checkingYearClose = openPeriod2 ? false : true;

  const draftFooterOption =
    mode !== 'edit'
      ? {
          name: 'Save as Draft',
          onClick: () => {
            if (noError) {
              handleSubmit(data => onSubmit(data, 'draft'))();
            }
          },
          disabled:
            checkingYearClose ||
            checkItemLength() === false ||
            checkGRTNItem() === true,
          // !noError ||
          // (itemHasValue?.every(s => s === false) &&
          //   itemHasValueAmt?.every(s => s === false)),
          color: 'primary',
        }
      : {
          name: editData?.mode === 'resubmit' ? 'Save' : 'Save as Draft',
          onClick: () => {
            if (noError) {
              handleSubmit(data =>
                onSubmit(
                  data,
                  editData?.mode === 'resubmit' ? 'reject' : 'draft',
                ),
              )();
            }
          },
          disabled:
            editData?.mode === 'resubmit'
              ? !!errors?.DocDate ||
                !!errors?.TrxDate ||
                !!errors?.SupplierID ||
                !!errors?.DocRef ||
                !!errors?.Description ||
                checkingYearClose ||
                !noError ||
                checkItemLength() === false
              : !noError ||
                checkItemLength() === false ||
                checkGRTNItem() === true ||
                checkingYearClose,
          color: 'primary',
        };

  const submitFooterOption =
    mode !== 'edit'
      ? {
          name: 'Save as Submit',
          onClick: () => {
            if (noError) {
              if (checkGPPolicy() === false) {
                setGPPolicyControl(true);
              } else if (checkICTrxType() === false) {
                setICTrxType(true);
              } else {
                handleSubmit(data => onSubmit(data, 'submit'))();
                setIsSubmit(true);
              }
            }
            setIsSubmit(true);
          },
          disabled:
            checkingYearClose ||
            checkItemLength() === false ||
            checkGRTNItem() === true ||
            !noError ||
            (itemHasValue?.every(s => s === false) &&
              itemHasValueAmt?.every(s => s === false)),
          color: 'primary',
        }
      : {
          name: 'Save as Submit',
          onClick: () => {
            if (noError) {
              if (checkGPPolicy() === false) {
                setGPPolicyControl(true);
              } else if (checkICTrxType() === false) {
                setICTrxType(true);
              } else {
                handleSubmit(data => onSubmit(data, 'submit'))();
                setIsSubmit(true);
              }
            }
            setIsSubmit(true);
          },
          disabled:
            checkingYearClose ||
            !noError ||
            checkItemLength() === false ||
            checkGRTNItem() === true,
          color: 'primary',
        };

  let footerOptions: any[] = [draftFooterOption, submitFooterOption];

  return (
    <>
      {CreateLoading && <Loading />}
      {UpdateLoading && <Loading />}
      {DocumentLoading && <Loading />}
      {DOHeaderLoading && <Loading />}
      {GPPolicyLoading && <Loading />}
      {WarehouseLoading && <Loading />}
      {APInvoiceLoading && <Loading />}
      {DOHeaderListLoading && <Loading />}
      {TransactionTypeLoading && <Loading />}
      {OpenPeriodCheckDateLoading && <Loading />}
      {ItemByCompanyAssignmentLoading && <Loading />}
      <MainHeader
        onClick={() => {
          {
            history.push(`/general-purchase/${CompanyID}/good-return-note`);
            localStorage?.removeItem('searchFilter');
          }
        }}
        mainBtn="back"
        smTitle={smTitle.GENERAL_PURCHASES}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Goods Return Note', current: true },
        ]}
        rightRouteSegments={[{ name: `${formMode}`, current: true }]}
      />

      <ContentWrapper footer>
        <form onSubmit={handleCLick} id="submit-form">
          <CardContents
            section={{
              header: {
                title: 'Goods Return Note Details',
              },
            }}
          >
            <Controller
              control={control}
              ref={register}
              name="docDate"
              required
              className="left"
              label="DO Date"
              fullWidth
              as={KeyboardDatePicker}
              defaultValue={editData ? editData?.DocDate : new Date()}
              value={new Date()}
              format="dd/MM/yyyy"
              onChange={(value, newValue: any) => {}}
              showTodayButton
            />

            <Controller
              control={control}
              ref={register}
              name="trxDate"
              required
              className="right"
              label="Transaction  Date"
              fullWidth
              as={KeyboardDatePicker}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              value={new Date()}
              format="dd/MM/yyyy"
              onChange={(e: Date | null) => {}}
              showTodayButton
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose ? true : false}
            />

            {!SupplierLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getSupplierByDOStatus?.filter(
                    x => x?.CreditorAccountID === editData?.SupplierID,
                  )[0];

                  return (
                    <Autocomplete
                      options={getSupplierByDOStatus || []}
                      getOptionLabel={option => option?.CompanyName}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('SupplierID', newValue?.CreditorAccountID);
                        setSuppID(watch(`SupplierID`));
                        setSelectedDO(new Set());
                        setDoHeaderArray([]);
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.CompanyName}</span>;
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.SupplierID?.message}
                              error={errors?.SupplierID ? true : false}
                              label="Supplier"
                              style={{ width: '100%' }}
                              margin="dense"
                              required
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
                label="Supplier"
                name="SupplierID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                helperText={errors?.SupplierID?.message}
                error={errors?.SupplierID ? true : false}
                defaultValue={editData?.SupplierID}
                required
              />
            )}

            {!WarehouseLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getDeliveryLocation?.filter(
                    x => x?.DeliveryLocationID === editData?.DeliveryLocationID,
                  )[0];
                  return (
                    <Autocomplete
                      options={getDeliveryLocation || []}
                      getOptionLabel={option => option?.Name}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('LocationID', newValue?.DeliveryLocationID);
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.Name}</span>;
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.LocationID?.message}
                              error={errors?.LocationID ? true : false}
                              label="Location"
                              style={{ width: '100%' }}
                              margin="dense"
                              required
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
                label="Location"
                name="LocationID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                defaultValue={editData?.DeliveryLocationID}
                helperText={errors?.LocationID?.message}
                error={errors?.LocationID ? true : false}
                required
              />
            )}

            <Controller
              as={TextField}
              name="DocRef"
              // className="left"
              fullWidth
              required
              label="Do No"
              defaultValue={editData?.RefNo}
              control={control}
              helperText={errors?.DocRef?.message}
              error={!!errors?.DocRef}
            />

            <Controller
              as={TextField}
              name="Description"
              fullWidth
              required
              label="Description"
              defaultValue={editData?.Description}
              control={control}
              helperText={errors?.Description?.message}
              error={!!errors?.Description}
            />

            <FileUploadInput
              label="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                    />
                  ))}
                </>
              }
            />
          </CardContents>

          {/* Revamp part starts here  */}
          <CardContents
            contentStyle={{ display: 'block' }}
            section={{
              header: {
                title: 'GRTN Items',
                onClickAction: () => {
                  setTempSelectedDO(new Set(selectedDO));
                  setOpen(true);
                  loadDOHeader({
                    variables: {
                      SupplierID: suppID,
                      CompanyID: CompanyID,
                      ApprovalStatus: ApprovalStatus.Completed,
                    },
                  });
                },
                icon: watch('SupplierID') ? (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      margin: '0',
                      background: theme.palette.primary.main,
                      boxShadow: `1px 1px 4px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                      marginTop: '10px',
                    }}
                  />
                ) : null,
              },
            }}
          >
            <div>
              {doHeaderArray?.length === 0 ? (
                <EmptyList
                  title="No Record Found"
                  subtitle="Add New Record now."
                />
              ) : (
                !DOHeaderListLoading &&
                doHeaderArray && (
                  <GeneralGRTNContentListing
                    GRTNLoading={DOHeaderLoading}
                    doArr={doHeaderArray}
                    mode={mode}
                    editData={editData ?? null}
                    setNoError={setNoError}
                    useFormProps={{
                      clearErrors,
                      setError,
                      errors,
                      watch,
                      register,
                      control,
                      setValue,
                    }}
                    itemHasValueQty={setItemHasValue}
                    itemHasValueAmt={setItemHasValueAmt}
                    GRTNHeaderID={GRTNHeaderID}
                    CompanyID={CompanyID}
                    selectedDate={doDate}
                    InvMap={getAPInvoice}
                    suppID={suppID}
                    sortingFormatDate={sortingFormatDate}
                  />
                )
              )}
            </div>
          </CardContents>
        </form>
        <TooltipAmtFooter data={checkItemQty()} module={'grtn-draft'} />

        {footerOptions?.length > 0 ? (
          <Footer options={[...footerOptions]} />
        ) : null}
      </ContentWrapper>

      {!DOHeaderLoading && (
        <CommonDialog
          fullWidth={true}
          open={open}
          onClose={() => {}}
          sections={{
            header: {
              title: 'GRTN Items',
            },
            body: () => (
              <div className="content-container">
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={
                          getGeneralDO
                            ?.filter(
                              m =>
                                m?.DOItem?.filter(
                                  q =>
                                    !(mode === 'add'
                                      ? q?.UpToDateQty === 0
                                      : editData?.GRTNHeaderItem?.find(
                                          f => f?.DOItemID === q?.DOItemID,
                                        )?.DOItem?.AcceptedQty !== undefined
                                      ? editData?.GRTNHeaderItem?.find(
                                          f => f?.DOItemID === q?.DOItemID,
                                        )?.DOItem?.AcceptedQty === 0
                                      : q?.UpToDateQty === 0),
                                )?.length > 0,
                            )
                            ?.filter(
                              dateFil => dateFil?.DocDate <= formattedDate,
                            )
                            ?.filter(
                              A =>
                                !getAPInvoice?.some(
                                  C => C?.DO?.DOHeaderID === A?.DOHeaderID,
                                ),
                            )
                            ?.sort((a, b) => {
                              return a.DocNo.localeCompare(b.DocNo);
                            }) || []
                        }
                        getOptionLabel={option => `${option?.DocNo}`}
                        fullWidth
                        onChange={(event, value, reason) => {
                          const selected = new Set();

                          value?.map(x => {
                            selected.add(x?.DOHeaderID);
                          });

                          setTempSelectedDO(new Set(selected));

                          if (reason === 'clear') {
                            setTempSelectedDO(new Set());
                          }
                        }}
                        defaultValue={Array.from(selectedDO).map(id =>
                          doFormArray?.find(doh => doh?.DOHeaderID === id),
                        )}
                        renderOption={(option, { selected }) => (
                          <Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              value={option?.DOHeaderID}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              color="primary"
                              onChange={event => {
                                handleCheckboxDOChange(event);
                              }}
                            />
                            {`${option?.DocNo}`}
                          </Fragment>
                        )}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                variant="outlined"
                                style={{ width: '100%' }}
                                label="GRTN"
                                margin="normal"
                                onKeyDown={(event: any) => {
                                  if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                    setTempSelectedDO(new Set());
                                  }
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    );
                  }}
                  name="GrtnIDs"
                  label="GRTN"
                  margin="normal"
                  autoComplete="off"
                  fullWidth
                  multiline={true}
                  control={control}
                  select
                  ref={register}
                />
              </div>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => {
                      setOpen(false);
                      setTempSelectedDO(new Set());
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },

                {
                  displayText: 'Confirm',
                  props: {
                    onClick: () => {
                      setDoHeaderArray([]);
                      setSelectedDO(new Set(tempSelectedDO));
                      tempSelectedDO &&
                        loadDOsList({
                          variables: {
                            DOHeaderID: Array.from(tempSelectedDO) as string[],
                            CompanyID: CompanyID,
                            SupplierID: watch('SupplierID'),
                            DeliveryLocationID:
                              watch('DeliveryLocationID') ?? null,
                          },
                        });
                      selectedDO && setTempSelectedDO(new Set());
                      !DOHeaderListLoading && setOpen(false);
                    },
                    variant: 'contained',
                    color: 'primary',
                    type: 'submit',
                  },
                },
              ],
            },
          }}
        />
      )}

      <ExitConfirmationDialog
        exitDialog={exitDialog}
        setExitDialog={setExitDialog}
        path={() =>
          history.push(`/general-purchase/${CompanyID}/good-return-note`)
        }
      />

      <ErrorDialog
        errorDia={gpPolicyControl}
        setErrorDia={setGPPolicyControl}
        errorMsg={'Please setup Inventory Clearance Account'}
        errorHeaderMsg={'Error!'}
      />

      <ErrorDialog
        errorDia={icTrxType}
        setErrorDia={checkICTrxType}
        errorMsg={'Please setup Transaction Type'}
        errorHeaderMsg={'Error!'}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  );
};
