import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import { useGetCompanyNameQuery } from 'generated/graphql'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const GLJournalByCostCentreReport = () => {
  const history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const parameters = [
    { name: 'accountid', value: accountID },
    { name: 'companyid', value: CompanyID },
    {
      name: 'startdate',
      value: new Date(editData?.StartDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'enddate',
      value: new Date(editData?.EndDate.setHours(0, 0, 0)).toISOString(),
    },
    { name: 'fyear', value: editData?.Year },
    { name: 'fperiod', value: editData?.Period },
    { name: 'accountcode', value: editData?.AccountCode },
    { name: 'journaltype', value: editData?.JournalType },
    { name: 'journalno', value: editData?.JournalNo },
    { name: 'accountname', value: editData?.AccountName },
    { name: 'remark', value: editData?.Description },
    { name: 'costcentrecode', value: editData?.CostCentreCode },
    { name: 'referenceno', value: editData?.ReferenceNo },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      <MainHeader
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        mainBtn="close"
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reporting' },
          { name: 'GL Journal By Department', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('GLJournalByCostCentre', parameters),
          host: accountxReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
