import 'app.css'
import 'devexpress-reporting/dx-webdocumentviewer'
import ko from 'knockout'
import React from 'react'

var docURL: any = '',
  docHost: any = '',
  docExportFormat: any = []

export const accountxReportUrl =
  process.env.REACT_APP_LOCAL_DB === 'staging'
    ? 'https://accountxuatreporting.ifca.asia'
    : process.env.REACT_APP_LOCAL_DB === 'training'
    ? 'https://accountxreporting-training.accountx.asia'
    : process.env.REACT_APP_LOCAL_DB === 'demo'
    ? 'https://accountxreporting-training.accountx.asia'
    : process.env.REACT_APP_LOCAL_DB === 'prod'
    ? 'https://accountxreporting.accountx.asia'
    : process.env.REACT_APP_LOCAL_DB === 'promenadeuat'
    ? 'https://accountxreporting-promenade-uat.accountx.asia'
    : process.env.REACT_APP_LOCAL_DB === 'promenade'
    ? 'https://accountxreporting-promenade.accountx.asia'
    : 'https://accountxdevreporting.ifca.asia'

// export const menuxReportUrl = // 'http://localhost:61094'
// 	process.env.REACT_APP_ENV === 'dev'
// 		? 'https://menuxdevreporting.ifca.asia/'
// 		: process.env.REACT_APP_ENV === 'uat'
// 		? 'https://menuxuatreporting.ifca.asia/'
// 		: process.env.REACT_APP_ENV === 'prod'
// 		? 'https://menuxreporting.menux.asia/'
// 		: 'https://menuxdevreporting.ifca.asia/';

var docURL: any = '',
  docHost: any = '',
  docExportFormat: any = []

class RenderReportViewer extends React.Component {
  reportUrl: ko.Observable<any>
  previewModel: ko.Observable<any>
  requestOptions: {
    host: string
    invokeAction: string
  }
  callbacks: any | any
  viewer: any
  refs: any

  constructor(props) {
    super(props)

    this.reportUrl = ko.observable()
    this.previewModel = ko.observable()
    this.requestOptions = {
      host: docHost,
      invokeAction: '/WebDocumentViewer/Invoke',
    }

    this.previewModel.subscribe(function(newModel) {
      if (newModel) newModel.reportPreview.zoom(-1)
    })

    this.callbacks = {
      CustomizeParameterEditors: function(s, e) {
        if (e.parameter.type === 'System.DateTime') {
          e.info.editor = e.info.editor
          e.info.editor.extendedOptions =
            (e.info.editor.extendedOptions || {},
            {
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              useMaskBehavior: true,
            })
        }
      },
    }

    if (docExportFormat.length > 0) {
      this.callbacks = {
        CustomizeExportOptions: function(s, e) {
          docExportFormat.forEach(doc => {
            e.HideFormat(doc)
          })
        },
      }
    }
  }

  getReport() {
    this.previewModel().OpenReport(docURL)
  }

  render() {
    return (
      <div>
        <div>
          <div
            ref="viewer"
            data-bind="dxReportViewer: $data"
            style={{ width: '100%', height: '900px' }}
          ></div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    ko.applyBindings(
      {
        reportUrl: this.reportUrl,
        viewerModel: this.previewModel,
        requestOptions: this.requestOptions,
        callbacks: this.callbacks,
      },
      this.refs.viewer
    )

    this.getReport()
  }
  componentWillUnmount() {
    ko.cleanNode(this.refs.viewer)
  }
}

function ReportViewer(props: any) {
  docURL = props.url
  docHost = props.host
  docExportFormat = props.excludedExportFormat
  return (
    <>
      <RenderReportViewer />
    </>
  )
}

export default ReportViewer
