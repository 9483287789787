import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { formatDashDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Menu, MenuItem, TextField, useMediaQuery } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import { statusInput } from 'containers/ARAPModule/Function/DocumentChecker'
import { useARCreateUpdateMutation } from 'containers/ARModule/Hooks/useARCreateUpdateMutation'
import { ARItemDialog } from 'containers/ARModule/components/ARItemDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  RecordStatus,
  useDocumentListingLazyQuery,
  useGetArAllocationDocumentLazyQuery,
  useGetBillItemListingLazyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetMasterCoaQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { ARCNItemDetail } from '../ARCreditNoteDetail/ARCNDetail'

interface CreditNoteProps {
  DocDate: string
  TrxDate: string
  DocAmt: number
  Remark: string
  Description: string
  approval: string
  user: string
  DebtorAccountID: string
  CreditDebitID: string
  BillItemID: string
  RefNo: string
  CompanyID: string
  CreditNoteID: string
  TaxRate: number
  TaxSchemeID: string
  DocNo: string
  Allocation: any[]
}

export const ARCreditNoteFormWithAllocation = (props: any) => {
  const { formMode, docType } = props
  const { CompanyID, CreditNoteID }: any = useParams()
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [detailMode, setDetailMode] = useState('')

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [selectedTax, setSelectedTax] = useState(null)

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [initDocs, setInitDocs] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const [creditNoteItemData, setCreditNoteItemData] = useState([])
  const [edit, setEdit] = useState(false)
  const [singleRemark, setSingleRemark] = useState(null)
  // const [audioInput, setAudioInput] = useState('')
  const [voiceRemark, setVoiceRemark] = useState('')

  const [record, setRecord] = useState(false)
  const [text, setText] = useState(editData?.Remark ?? '')
  const [selectedBillItem, setSelectedBillItem] = useState(null)

  //For Default Department
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)

  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  // useEffect(() => {
  //   if (CreditNoteID && editData?.Remark) {
  //     setText(editData?.Remark)
  //     handleSingleRemark(CreditNoteID && editData?.Remark)
  //     setAudioInput(CreditNoteID && editData?.Remark)
  //   }
  // }, [editData])

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  // checking the document number setup

  let refTable, routeSegments, itemRefTable, itemRefTableKey
  switch (docType) {
    case 'credit-note':
      routeSegments = 'Credit Note'
      refTable = 'AR_CreditNote'
      itemRefTable = 'ARCreditNoteItem'
      itemRefTableKey = 'CreditNoteItemID'
      break

    default:
      break
  }

  const [docNoChecker, setDocNoChecker] = useState(false)

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: refTable },
    onCompleted: ({ getDocumentNumberHeader }) => {
      if (
        (getDocumentNumberHeader?.length > 0 &&
          getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)) ||
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) ||
        (getDocumentNumberHeader?.length === 0 && docType === 'credit-note')
      ) {
        setDocNoChecker(true)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const CreditNoteFormSchema = yup.object().shape({
    DebtorAccountID: CommonYupValidation.requireField(
      'Debtor Name is required'
    ),
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    RefNo: yup.string().required('Reference No is Required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
    unregister,
  } = useForm<CreditNoteProps>({
    defaultValues: {
      RefNo: editData ? editData?.RefNo : '',
      DocDate: new Date()?.toISOString(),
      Remark: editData ? editData?.Remark : '',
      Description: editData ? editData?.Description : '',
      DebtorAccountID: editData ? editData?.DebtorAccountID : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(CreditNoteFormSchema),
  })

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  // Upload Document
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  // Company Name
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
    onCompleted: ({ getCompany }) => {
      loadDefaultCostCentre(getCompany[0]?.DefaultCostCentre)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  // Tax
  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const {
    loading: DocDateValidationLoading,
    error: DocDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  //Debtor Account Company Assignment//

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })
  let debtorAcc = getDebtorAccount
  //   For allocation purposes
  const [allocatedObj, setAllocatedObj] = useState({})
  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      called: getAllocationDocumentCalled,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetArAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument?.map((alloc, index) => {
          arr.push({
            AllocationID: alloc?.AllocationID,
            CreditOrDebit: alloc?.CreditOrDebit,
            DocDate: alloc?.DocDate,
            DocNo: alloc?.DocNo,
            Description: alloc?.Description,
            // for previous allocated amt on the same doc
            AllocationAmt: Number(
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
            ),
            // balance amount + previous allocated amt on the same doc
            BalanceAmt:
              Number(alloc?.BalanceAmt) +
              Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
            // credit or debit id
            [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
            // credit or debit ref table
            [`${alloc?.CreditOrDebit}RefTable`]: alloc[
              `${alloc?.CreditOrDebit}RefTable`
            ],
            // credit or debit doc no
            [`${alloc?.CreditOrDebit}DocNo`]: alloc[
              `${alloc?.CreditOrDebit}DocNo`
            ],
          })

          return alloc
        })
        append(arr)
      }
    },
  })

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(
        `${errorMessage} that is assigned to the item: "${selectedBillItem}."`
      )
    },
  })

  // Item
  const [
    fetchBillItem,
    {
      loading: BillItemLoading,
      error: BillItemError,
      data: { getBillItem } = { getBillItem: [] },
    },
  ] = useGetBillItemListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { RecordStatus: RecordStatus.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const {
    loading: TaxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        // previewFiles?.push(
        //   ...DocumentListing?.filter(
        //     doc => doc?.description !== 'document_pdf'
        //   )?.map(x => x?.fileURL)
        // )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
  // call fetchDocuments to get existing attachment from db
  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: { refID: CreditNoteID },
        })
      }
  }, [formMode])

  useEffect(() => {
    const findCoa = getBillItem
      ?.filter(x => {
        return x?.BillItemID === menu?.obj?.BillItemID
      })
      .map(y => {
        return y?.MasterCOAID
      })

    if (!!menu?.obj?.BillItemID) {
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: findCoa[0],
          IsLastNode: true,
        },
      })
    }
  }, [menu?.obj])

  // applies after navigating out from item form, where "Attachments" is passed as "files" in location.state
  // useEffect(() => {
  //   if (editData?.files) {
  //     setFiles(editData?.files)
  //     setPreviewFiles(
  //       editData?.files?.map(file => {
  //         return URL.createObjectURL(file)
  //       })
  //     )
  //   }
  // }, [editData, ID])

  useEffect(() => {
    if (editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(alloc => {
        if (!(alloc[`${alloc?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] =
            alloc?.AllocationAmt
        }
      })
    }
  }, [editData])

  useEffect(() => {
    if (editData?.DebtorAccountID) {
      loadAllocationDocument({
        variables: {
          entityID: CreditNoteID,
          creditorDebtorID: editData?.DebtorAccountID,
          companyID: CompanyID,
          refTable: 'AR_CreditNote',
        },
      })
    }
  }, [allocatedObj, editData])

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const {
    handleMutation,
    mutationLoading,
    mutationCalled,
  } = useARCreateUpdateMutation(isSubmit, {
    docType: docType,
    mode: mode,
    CompanyID: CompanyID,
    setErrorDia,
    setErrMsg,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Allocation',
  })

  /* -------------------------------------------------- */
  /*                   DOC NO CHECKING                  */
  /* -------------------------------------------------- */

  //checking for existing docNo
  const str = watch('DocNo')

  const findDuplicates = arr => {
    if (
      (formMode === 'add' && arr?.includes(str)) ||
      (formMode === 'edit' &&
        arr?.filter(x => x !== editData?.DocNo)?.includes(str))
    ) {
      return true
    } else {
      return false
    }
  }

  const checkSetupNumStruc = () => {
    if (
      (getDocumentNumberHeader?.length > 0 &&
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) &&
        getDocumentNumberHeader[0]?.SampleOutput === '') ||
      (getDocumentNumberHeader
        ?.filter(x => x?.RefTable === 'AR_CreditNote')
        ?.find(y => y?.SampleOutput === '') &&
        docNoChecker === true) ||
      (getDocumentNumberHeader?.length === 0 &&
        docNoChecker === true &&
        docType === 'credit-note')
    ) {
      return true
    } else return false
  }

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const inputData = (data, status) => {
    return {
      CreditNoteID: editData?.CreditNoteID,
      CompanyID: CompanyID,
      DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
      TransactionDate: formatDashDate(new Date(data?.TrxDate)?.toISOString()),
      DocAmt: totalAmount,
      ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
      DebtorAccountID: data?.DebtorAccountID,
      Remark: data?.Remark,
      Description: data?.Description,
      // RefTable: creditDebitRefTable,
      ...(watch('CreditDebitID') ? { RefRecordID: data?.CreditDebitID } : null),
      RefNo: data?.RefNo,
      ApprovalStatus: statusInput(status),
      Attachment: files,
    }
  }

  // //To calculate Total Amount of Credit Note
  let totalAmount = creditNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )
  // //To calculate Total Amount of Credit Note
  let totalTaxAmt = creditNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  // // get amount before tax
  let amtBeforeTax = totalAmount - totalTaxAmt
  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const handleCreditorDebtorChange = event => {
    remove()
    loadAllocationDocument({
      variables: {
        ...(editData && editData?.DebtorAccountID === event
          ? { entityID: [CreditNoteID] }
          : null),
        creditorDebtorID: event,
        companyID: CompanyID,
        refTable: 'AR_CreditNote',
      },
    })
  }

  const getTotalAmt = () => {
    if (creditNoteItemData?.length > 0) {
      return creditNoteItemData.reduce((prevValue, currValue) => {
        return prevValue + currValue?.DocAmt
      }, 0)
    } else {
      return 0
    }
  }
  const getAllocationTotal = () => {
    if (watch('Allocation')?.length > 0) {
      return watch('Allocation').reduce((prevValue, currValue) => {
        return prevValue + currValue?.AllocationAmt
      }, 0)
    } else {
      return 0
    }
  }

  const getBalanceAmt = () => {
    if (watch('Allocation')?.length > 0) {
      return watch('Allocation')?.reduce((prevValue, currValue) => {
        return prevValue + currValue?.BalanceAmt
      }, 0)
    } else {
      return 0
    }
  }

  const docAmtChecker = () => {
    if (getTotalAmt > getBalanceAmt) {
      return true
    } else {
      return false
    }
  }

  /* -------------------------------------------- */
  /*                USE EFFECT                    */
  /* -------------------------------------------- */

  useEffect(() => {
    if (CreditNoteID && editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  useEffect(() => {
    if (editData) {
    }
  }, [])

  //   this is where i get the data for item when editing the form

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const creditNoteItems = editData?.[itemRefTable]?.map((el, index) => {
        return {
          Sequence: el?.Sequence ?? index + 1,
          CreditNoteItemID: el?.CreditNoteItemID,
          BillItemName: el?.BillItem?.Name,
          BillItemID: el?.BillItemID,
          CostCentreName: el?.CostCentre?.Name,
          CostCentreID: el?.CostCentreID,
          Amount: el?.Amount,
          TaxSchemeID: el?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
        }
      })

      creditNoteItemData.push(...creditNoteItems)
    }
  }, [formMode, editData])

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteCreditNoteItem = (delInd: number) => {
    creditNoteItemData.splice(delInd, 1)
  }

  /* ------------------------------------------- */
  /*                ON SUBMIT                    */
  /* ------------------------------------------- */
  // updated tax effective date

  const updateNewCNItem = (newDate, tED) => {
    const arr = []
    creditNoteItemData?.map(x => {
      let newTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null

      if (newTax !== null) {
        arr.push({
          CreditNoteItemID: x?.CreditNoteItemID,
          BillItemID: x?.BillItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.BillItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: newTax?.Rate,
          TaxAmt: x?.Amount * (newTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (newTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
  }

  const onSubmit = (data, status) => {
    if (checkSetupNumStruc() === true) {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.errorNumberingStructure())
    } else {
      //add the data with GraphQL mutation
      handleMutation({
        variables: {
          input: inputData(data, status),

          itemInput:
            creditNoteItemData?.map(x => {
              return {
                CreditNoteID: CreditNoteID,
                ItemID: x?.CreditItemID,
                BillItemID: x?.BillItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: Number(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
              }
            }) ?? [],
          allocationInput:
            watch('Allocation')
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(x => {
                const mainCreditOrDebit =
                  x?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                return {
                  ...(x?.AllocationID
                    ? { AllocationID: x?.AllocationID }
                    : null),
                  AllocationAmt: Number(x?.AllocationAmt),
                  CreditOrDebit: x?.CreditOrDebit,
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${x?.CreditOrDebit}RefTable`]: x?.[
                    `${x?.CreditOrDebit}RefTable`
                  ],
                  [`${x?.CreditOrDebit}ID`]: x?.[`${x?.CreditOrDebit}ID`],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: refTable,
                }
              }) ?? [],
        },
      })
    }
  }

  const createUpdateCalled = mutationCalled

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  const loading = getBillItem
    ? BillItemLoading
    : getCostCentre
    ? CostCentreLoading
    : ''

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true
  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableCreditNotesDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableCreditNotesUpdate,
          })
        ? true
        : false,
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(creditNoteItemData) === 0 ||
      calcTotal(creditNoteItemData) === undefined ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,

      // for item
      itemSuffixID: itemRefTableKey,
      itemTableName: itemRefTable,
      // for attachment
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
    })

  return (
    <>
      {loading && <Loading />}
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {mutationLoading && <Loading />}
      {documentLoading && <Loading />}
      {masterCOALoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            if (hasChanges() === true) {
              setOpenExitConf(true)
            } else {
              history.push({
                pathname: `/account-receivable/${CompanyID}/${docType}`,
              })
            }
          }
        }}
        smTitle={smTitle?.ACCOUNT_RECEIVABLE}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Receivable Main Menu' },
          { name: 'Accounts Receivable Submenu' },
          { name: routeSegments, current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />
      <ContentWrapper footer float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="DocDate"
              required
              label="Doc Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewCNItem(date, taxEffectiveList)
              }}
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              showTodayButton
              className={'left'}
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              defaultValue={editData ? editData?.DocDate : new Date()}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>
          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          {!DebtorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = debtorAcc?.filter(
                  x => x?.DebtorAccountID === editData?.DebtorAccountID
                )[0]

                return (
                  <Autocomplete
                    options={
                      debtorAcc?.sort((a, b) => {
                        return a.DebtorName.localeCompare(b.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DebtorName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      handleCreditorDebtorChange(newValue?.DebtorAccountID)
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                      clearErrors('DebtorAccountID')
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.DebtorName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData?.DebtorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            defaultValue={editData?.Description}
            ref={register}
            disabled={mode === 'approve-reject'}
          />

          {/* <VoiceTextField
            name="Remark"
            mounted={true}
            label="Remark"
            value={audioInput}
            record={record}
            setRecord={setRecord}
            setValue={setValue}
            helperTextProps={{
              helperText: errors?.Remark?.message,
              error: errors?.Remark ? true : false,
            }}
            defaultValue={getValues('Remark')}
            customOnchange
            register={register}
            control={control}
            clearErrors={clearErrors}
            handleCustomOnChange={e => {
              setText(e.target.value)
              handleSingleRemark(e.target.value)
            }}
          /> */}

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>
        <ARCNItemDetail
          CN={true}
          titleLabel={'Charge to'}
          itemData={creditNoteItemData}
          setOpenDialog={setOpenItemDialog}
          fetchBillItem={fetchBillItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getBillItem={getBillItem}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={'CreditNoteItemID'}
        />

        {watch('DebtorAccountID') && !getAllocationDocumentLoading && (
          <FormAllocationTableContent
            headerLeftTitle={'Allocation'}
            fields={fields}
            watch={watch}
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setError={setError}
            allocatedObj={allocatedObj}
            docAmt={getTotalAmt()}
            totalAmt={getAllocationTotal()}
            headerStyle={{
              backgroundColor: '#faf2e8',
              color: '#ff9800',
              fontWeight: '700',
            }}
            hasCheckbox={true}
          />
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEdit(true)
            setOpenItemDialog(true)
            setDetailMode('edit')
            fetchBillItem()
            fetchCostCentre()
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteCreditNoteItem(menu?.index)
          }}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      {!BillItemLoading && !CostCentreLoading && (
        <ARItemDialog
          CompanyID={CompanyID}
          title={'Charge to'}
          fromAP={true}
          openItemDialog={openItemDialog}
          setOpenItemDialog={setOpenItemDialog}
          watch={watch}
          menu={menu}
          BillItemLoading={BillItemLoading}
          fetchBillItem={fetchBillItem}
          getBillItem={getBillItem}
          isEdit={edit}
          itemData={creditNoteItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          setDetailMode={setDetailMode}
          resetMenu={resetMenu}
          primaryItemKey="CreditNoteItemID"
          defaultCostCentre={defaultCostCentre}
          getMasterCOA={getMasterCOA}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/${docType}`)
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter
        data={creditNoteItemData}
        module={'credit-note-draft'}
      />
    </>
  )
}
